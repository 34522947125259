<template>
    <preview-responsive-container>
        <cms-block-builder v-if="cmsPage" :blocks="cmsPage.blocks"/>
    </preview-responsive-container>
</template>

<script>
import CmsBlockBuilder from "@/components/editor/rendering/blocks/cms-block-builder.vue";
import CmsBlockFactory from "@/helpers/editor/blocks/CmsBlockFactory.js";
import PreviewResponsiveContainer from "@/layout/preview-responsive-container.vue";
import Network from "../../vue-components/helpers/Network.js";

// web-preview #hot-reload-debug
export default {
    name: `web-preview`,
    components: {
        CmsBlockBuilder,
        PreviewResponsiveContainer,
    },
    metaInfo() {
        if (this.cmsPage) {
            return this.cmsPage.getMeta();
        } else {
            return null;
        }
    },
    data() {
        return {
            loading: false
        }
    },
    created() {
        window.cmsFactory = new CmsBlockFactory();
        this.$store.state.editMode = false;
    },
    activated() {
        this.init();
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            this.showLoading();
            Network.get(`/api/page/${this.$route.params.pageId}`)
                .then(resp => {
                    this.$store.commit(`setPage`, {page: resp.data, route: this.$route});
                    this.hideLoading();
                    this.loading = false;
                })
                .catch(_ => {
                    this.loading = false;
                })
        }
    }
}
</script>

<style lang="scss">
</style>
